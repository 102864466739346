/* MUI DateCalendar custom styles */
.MuiPickersDay-root.Mui-selected {
  background-color: #ec9393 !important;
}

.MuiPickersDay-today {
  background-color: #374151 !important;
  color: #fff !important;
}

.MuiTypography-root.MuiTypography-caption {
  font-size: 12px !important;
  color: #374151 !important;
  font-weight: 700;
}

.MuiPickersCalendarHeader-root {
  margin-top: 0;
  padding-left: 0;
  padding-right: 0;
}

.MuiPickersCalendarHeader-label {
  font-size: 15px !important;
  font-weight: 600;
}

.MuiPickersDay-root {
  font-size: 11px !important;
}

.MuiButtonBase-root.MuiIconButton-root.MuiPickersCalendarHeader-switchViewButton,
.MuiButtonBase-root.MuiIconButton-root.MuiPickersArrowSwitcher-button {
  background-color: #374151;
  color: #fff;
  width: 24px;
  height: 24px;
  padding: 0;
}

.MuiButtonBase-root.MuiIconButton-root.MuiPickersCalendarHeader-switchViewButton:hover,
.MuiButtonBase-root.MuiIconButton-root.MuiPickersArrowSwitcher-button:hover {
  background-color: #4b5563;
}